.App {
  font-family: 'Dosis', sans-serif;
}

body {
  padding-bottom: 3.5rem;
}

.content {
  width: calc(100% - 2rem);
  margin-left: auto;
  margin-right: auto;
  padding: 0 0.25rem;
}

.header, .footer {
  background-color: darkolivegreen;
  color: white;
}

.header {
  width: 100%;
  padding: 0.5rem 0;
}

.header-text {
  font-size: medium;
  font-weight: 400;
}

.footer {
  bottom: 0;
  width: 100%;
  height: 3.5rem;
  position: fixed;
}

.footer > .content {
  height:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  display: flex;
  height: 3rem;
  fill: white;
  margin-right: 0.25rem;
}

.discography {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.music-release {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.5);
  flex: 0 1 100%;
  margin-bottom: 1rem;
}

.music-release:hover {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
}

.music-release > a {
  text-decoration: none;
  color: inherit;
}

.release-description {
  padding: 0 0.5rem;
}

@media screen and (min-width: 30rem) {
  .music-release {
    flex: 0 1 49%;
  }

  .content {
    max-width: 42rem;
  }
}

@media screen and (min-width: 62rem) {
  .content {
    max-width: 58rem;
  }

  .music-release {
    flex: 0 1 32%;
  }
}

.cover-art {
  width: 100%;
}